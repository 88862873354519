import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyCZWEKAUaJgp8DiOuLl-W0N4jMsXG0AMzg',
  authDomain: 'codex-ppaz-costa.firebaseapp.com',
  projectId: 'codex-ppaz-costa',
  storageBucket: 'codex-ppaz-costa.appspot.com',
  messagingSenderId: '857280183',
  appId: '1:857280183:web:9847d59e23366d55a54732',
  measurementId: 'G-8FDFMMCN2F',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
